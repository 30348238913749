import type { ResourceType, UserType } from '~/generated/types';

export enum ModalType {
    CHOOSE_USER,
    CREATE_CLIENT,
    CREATE_EVENT_KIND,
    CHOOSE_RESOURCE,
    TEXT_INPUT,
}

export interface ModalState {
    title: string | null;
    active: ModalType | null;
    params?: Record<string, any> | null;
}

export const useModalState = () => {
    return useState<ModalState>('modals', () => {
        return {
            title: null,
            active: null,
        };
    });
};

export const useChooseUser = () => {
    const { $listen } = useNuxtApp();
    const modalState = useModalState();

    const choose = async(title: string, disabledIds: string[] = []): Promise<UserType> => {
        return new Promise((resolve, reject) => {
            modalState.value.title = title;
            modalState.value.params = {
                disabledIds,
            };
            modalState.value.active = ModalType.CHOOSE_USER;

            $listen('modal:closed', () => {
                reject();
            });

            $listen('modal:result', (result: any) => {
                modalState.value.active = null;
                resolve(result);
            });
        });
    };

    return {
        choose,
    };
};

export const useCreateClient = () => {
    const { $listen } = useNuxtApp();
    const modalState = useModalState();

    const create = async(defaultName: string): Promise<{ id: string, text: string }> => {
        return new Promise((resolve, reject) => {
            modalState.value.params = {
                defaultName,
            };
            modalState.value.active = ModalType.CREATE_CLIENT;

            $listen('modal:closed', () => {
                reject();
            });

            $listen('modal:result', (result: any) => {
                modalState.value.active = null;
                resolve(result);
            });
        });
    };

    return {
        create,
    };
};


export const useCreateEventKind = () => {
    const { $listen } = useNuxtApp();
    const modalState = useModalState();

    const create = async(defaultName: string): Promise<{ id: string, text: string }> => {
        return new Promise((resolve, reject) => {
            modalState.value.params = {
                defaultName,
            };
            modalState.value.active = ModalType.CREATE_EVENT_KIND;

            $listen('modal:closed', () => {
                reject();
            });

            $listen('modal:result', (result: any) => {
                modalState.value.active = null;
                resolve(result);
            });
        });
    };

    return {
        create,
    };
};

export const useChooseResource = () => {
    const { $listen } = useNuxtApp();
    const modalState = useModalState();

    const choose = async(title: string, disabledIds: string[], startDate: Date | undefined, endDate: Date | undefined): Promise<ResourceType> => {
        return new Promise((resolve, reject) => {
            modalState.value.title = title;
            modalState.value.active = ModalType.CHOOSE_RESOURCE;
            modalState.value.params = {
                disabledIds,
                startDate,
                endDate,
            };

            $listen('modal:closed', () => {
                reject();
            });

            $listen('modal:result', (result: any) => {
                modalState.value.active = null;
                resolve(result);
            });
        });
    };

    return {
        choose,
    };
};

export interface TextInputParams {
    inputLabel: string;
    acceptLabel?: string;
    text?: string;
    defaultValue?: string;
    multiline?: boolean;
    type?: 'text' | 'url' | 'tel' | 'email' | 'password' | 'number';
}

export const useTextInput = () => {
    const { $listen } = useNuxtApp();
    const modalState = useModalState();

    const input = async(title: string, options: TextInputParams): Promise<string> => {
        return new Promise((resolve, reject) => {
            modalState.value.title = title;
            modalState.value.active = ModalType.TEXT_INPUT;
            modalState.value.params = {
                text: options.text,
                inputLabel: options.inputLabel,
                acceptLabel: options.acceptLabel ?? 'Submit',
                multiline: options.multiline ?? false,
                type: options.type ?? 'text',
                defaultValue: options.defaultValue,
            };

            $listen('modal:closed', () => {
                reject();
            });

            $listen('modal:result', (result: any) => {
                modalState.value.active = null;
                resolve(result);
            });
        });
    };

    return {
        input,
    };
};
